import * as React from 'react';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { MainPicture } from '../../components/MainPicture';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { colors, device } from '../../components/layout/GlobalStyles';
import { Seo } from '../../components/Seo';

const StyledStaff = styled.div`
    padding: 2em 1em;

    .staff-person {
        margin: 2em;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            margin-top: 1.5em;
            color: ${colors.blueDark};
            font-weight: 700;
            font-size: 20px;
        }
        span {
            color: #6e8385;
        }
        .staff-picture {
            position: relative;
            width: 305px;
            img {
                height: 401px;
            }
            .staff-underlayer {
                position: absolute;
                width: 80%;
                opacity: 0.7;
                filter: blur(41px);
                border-radius: 20px;
                position: absolute;
                top: 0.5em;
                left: 0.5em;
                width: 305px;

                border-radius: 20px;
            }
        }
    }
    @media ${device.tablet} {
        padding-top: 0em;
        .staff-photos {
            display: flex;

            margin: 0 auto;

            justify-content: start;
        }
    }
    @media ${device.laptop} {
        .staff-photos {
            flex-wrap: wrap;
            max-width: 1110px;
            margin: 8em auto 2em;
        }
    }
`;

const Staff: Page = () => (
    <DefaultLayout currentPage="Our Staff">
        <Seo
            title="Meet The Staff At Magic Smiles"
            description="Meet magic smiles staff at your local dental office in Linden New jersey"
        />
        <StyledStaff>
            <MainPicture title="Magic Smiles Staff">
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    placeholder="none"
                    className="underlayer"
                    src="../../images/staff/group-pic.jpeg"
                    alt="staff smiling together"
                />
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    quality={100}
                    placeholder="none"
                    src="../../images/staff/group-pic.jpeg"
                    alt="staff smiling together"
                />
            </MainPicture>
            <div className="staff-photos">
                <div className="staff-person">
                    <div className="staff-picture">
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            className="staff-underlayer"
                            placeholder="none"
                            src="../../images/staff/andriana-new.jpg"
                            alt="Andriana - Office Manager"
                        />
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            placeholder="none"
                            src="../../images/staff/andriana-new.jpg"
                            alt="Andriana - Office Manager"
                        />
                    </div>
                    <p>Andriana</p>
                    <span>Office Manager</span>
                </div>
                <div className="staff-person">
                    <div className="staff-picture">
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            className="staff-underlayer"
                            placeholder="none"
                            src="../../images/staff/iwona-new.jpg"
                            alt="Iwona - Lead Dental Assistant"
                        />
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            placeholder="none"
                            src="../../images/staff/iwona-new.jpg"
                            alt="Iwona - Lead Dental Assistant"
                        />
                    </div>
                    <p>Iwona</p>
                    <span>Lead Dental Assistant</span>
                </div>
                <div className="staff-person">
                    <div className="staff-picture">
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            className="staff-underlayer"
                            placeholder="none"
                            src="../../images/staff/joanna-new.jpg"
                            alt="Joanna - Public Relations"
                        />
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            placeholder="none"
                            src="../../images/staff/joanna-new.jpg"
                            alt="Joanna - Public Relations"
                        />
                    </div>
                    <p>Joanna</p>
                    <span>Public Relations</span>
                </div>
                <div className="staff-person">
                    <div className="staff-picture">
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            className="staff-underlayer"
                            placeholder="none"
                            src="../../images/staff/grace.jpeg"
                            alt="Grace - Dental Assistant"
                        />
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            placeholder="none"
                            src="../../images/staff/grace.jpeg"
                            alt="Grace - Dental Assistant"
                        />
                    </div>
                    <p>Grace</p>
                    <span>Dental Assistant</span>
                </div>
                <div className="staff-person">
                    <div className="staff-picture">
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            className="staff-underlayer"
                            placeholder="none"
                            src="../../images/staff/yeranny-new.jpg"
                            alt="Yeranny - Dental Assistant"
                        />
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            placeholder="none"
                            src="../../images/staff/yeranny-new.jpg"
                            alt="Yeranny - Dental Assistant"
                        />
                    </div>
                    <p>Yeranny</p>
                    <span>Dental Assistant</span>
                </div>
                <div className="staff-person">
                    <div className="staff-picture">
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            className="staff-underlayer"
                            placeholder="none"
                            src="../../images/staff/tania-new.jpg"
                            alt="Tanya - Dental Assistant"
                        />
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            placeholder="none"
                            src="../../images/staff/tania-new.jpg"
                            alt="Tanya - Dental Assistant"
                        />
                    </div>
                    <p>Tanya</p>
                    <span>Dental Assistant</span>
                </div>
                <div className="staff-person">
                    <div className="staff-picture">
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            className="staff-underlayer"
                            placeholder="none"
                            src="../../images/staff/diana.jpeg"
                            alt="Diana - Dental Assistant"
                        />
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            placeholder="none"
                            src="../../images/staff/diana.jpeg"
                            alt="Diana - Dental Assistant"
                        />
                    </div>
                    <p>Diana</p>
                    <span>Dental Assistant</span>
                </div>

                <div className="staff-person">
                    <div className="staff-picture">
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            className="staff-underlayer"
                            placeholder="none"
                            src="../../images/staff/anastasiia.jpg"
                            alt="Anastasiia - Dental Assistant"
                        />
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            placeholder="none"
                            src="../../images/staff/anastasiia.jpg"
                            alt="Anastasiia - Dental Assistant"
                        />
                    </div>
                    <p>Anastasiia</p>
                    <span>Dental Assistant</span>
                </div>
                <div className="staff-person">
                    <div className="staff-picture">
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            className="staff-underlayer"
                            placeholder="none"
                            src="../../images/staff/evelyn-new.jpg"
                            alt="Evelyn - Senior Treatment Coordinator"
                        />
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            placeholder="none"
                            src="../../images/staff/evelyn-new.jpg"
                            alt="Evelyn - Senior Treatment Coordinator"
                        />
                    </div>
                    <p>Evelyn</p>
                    <span>Senior Treatment Coordinator</span>
                </div>
                <div className="staff-person">
                    <div className="staff-picture">
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            className="staff-underlayer"
                            placeholder="none"
                            src="../../images/staff/miri.jpeg"
                            alt="Miry - Treatment Coordinator"
                        />
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            placeholder="none"
                            src="../../images/staff/miri.jpeg"
                            alt="Miry - Treatment Coordinator"
                        />
                    </div>
                    <p>Miry</p>
                    <span>Treatment Coordinator</span>
                </div>
                <div className="staff-person">
                    <div className="staff-picture">
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            className="staff-underlayer"
                            placeholder="none"
                            src="../../images/staff/jasmine.jpeg"
                            alt="Jasmine - Front Desk Coordinator"
                        />
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            placeholder="none"
                            src="../../images/staff/jasmine.jpeg"
                            alt="Jasmine - Front Desk Coordinator"
                        />
                    </div>
                    <p>Jasmine</p>
                    <span>Front Desk Coordinator</span>
                </div>
                <div className="staff-person">
                    <div className="staff-picture">
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            className="staff-underlayer"
                            placeholder="none"
                            src="../../images/staff/ysa.jpeg"
                            alt="Ysa - Insurance Coordinator"
                        />
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            placeholder="none"
                            src="../../images/staff/ysa.jpeg"
                            alt="Ysa - Insurance Coordinator"
                        />
                    </div>
                    <p>Ysa</p>
                    <span>Insurance Coordinator</span>
                </div>
                <div className="staff-person">
                    <div className="staff-picture">
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            className="staff-underlayer"
                            placeholder="none"
                            src="../../images/staff/jocelin.jpeg"
                            alt="Jocelin - Insurance Coordinator"
                        />
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            placeholder="none"
                            src="../../images/staff/jocelin.jpeg"
                            alt="Jocelin - Insurance Coordinator"
                        />
                    </div>
                    <p>Jocelin</p>
                    <span>Insurance Coordinator</span>
                </div>
            </div>
            <h2 style={{ fontSize: '28px', textAlign: 'center', margin: '0 0 -94px' }}>
                Meet Our Dental Hygienists
            </h2>
            <div className="staff-photos">
                <div className="staff-person">
                    <div className="staff-picture">
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            className="staff-underlayer"
                            placeholder="none"
                            src="../../images/staff/miguel.jpeg"
                            alt="Miguel - Registered Dental Hygenist"
                        />
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            placeholder="none"
                            src="../../images/staff/miguel.jpeg"
                            alt="Miguel - Registered Dental Hygenist"
                        />
                    </div>
                    <p>Miguel</p>
                    <span>Registered Dental Hygenist</span>
                </div>
                <div className="staff-person">
                    <div className="staff-picture">
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            className="staff-underlayer"
                            placeholder="none"
                            src="../../images/staff/registered-new.jpg"
                            alt="Miguel - Registered Dental Hygenist"
                        />
                        <StaticImage
                            imgStyle={{ borderRadius: '20px' }}
                            quality={100}
                            placeholder="none"
                            src="../../images/staff/registered-new.jpg"
                            alt="Miguel - Registered Dental Hygenist"
                        />
                    </div>
                    <p>Jackie</p>
                    <span>Registered Dental Hygienist</span>
                </div>
            </div>
        </StyledStaff>
    </DefaultLayout>
);

export default Staff;
